import { OnInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { PageService } from '../services/page.service';
import { AccountService } from '../services/account/account.service';
import { BaseService } from '../services/base.service';
import { AppInjector } from '../services/injector';

declare var $: any;

export class BaseComponent implements OnInit {

    // CONFIGURATION
    loading = false;
    modalTitle = '';
    public titles;
    profile;
    serverUrl; // server base url for current root
    canInitData = true; // say if data have to be initialized
    canInitPaginate = true; // say if paginations have to be initialized
    requesting = false; // fire when requesting someting to the server
    requestings: any; // is the same like requesting but contains many state requesting (object)
    // DATA
    SOURCE; // we store data from server here
    currentItem; // contains the current item
    currentIndex // contains the current index for element we want to edit, delete,....
    paramsAreReady = false; // fire when all plugin options have been initialized
    redirect = false; // say if wa have to redirect the user after an action like add
    functionToCall: any; // allow to store a function we ant to fire dynamicly
    params: any; //store param from routes state that can be used in children component if needed
    localUrlWithParam: string = '';
    serverUrlWithParam: string = '';
    getDataAnyway: boolean = true;
    purgeCacheBefore = false;
    currentView = 'list'; // switch between view (list, edit,...)
    canSubscribeToData = true; // enable data subscriptoon
    serverPaginationUrl = '';

    // store all the forms
    // {add:{}, edit:{}}
    public forms;
    public steps;

    //INjector
    public pageService: PageService;
    public accountService: AccountService;

    constructor(private baseService: BaseService,
        public localUrl: string,
        public serverAdditionalUrlParam: string = '',
        public activatedRoute?: ActivatedRoute) {
        const injector = AppInjector.getInjector();
        this.pageService = injector.get(PageService);
        this.accountService = injector.get(AccountService);
        this.localUrlWithParam = this.localUrl;
        this.forms = {};
        this.titles = {};
        this.steps = {};
        this.requestings = {};
    }

    ngOnInit() {
        // get profile information
        this.profile = this.accountService.profile.value;
        //DEFINE SERVER URL
        this.serverUrl = this.baseService.getServerUrl() + this.serverAdditionalUrlParam;
        this.serverUrlWithParam = this.serverUrl;
        this.localUrlWithParam = this.localUrl;
        //Init route param container
        this.params = {};

        if (this.purgeCacheBefore) {
            this.baseService.setData([]);
        }

        // Subscribe to service Data
        if (this.canSubscribeToData) {
            this.baseService.dataSubject.subscribe(
                (res: any) => {
                    if (res) {
                        this.SOURCE = res;

                        if (this.canInitPaginate && res.data) {
                            //SEND PAGINATION INFO TO PROVIDER FOR PROCESSING if necessary
                            if (res.data.current_page) {
                                this.pageService.setPagination(res.data.current_page, res.data.last_page, this.localUrlWithParam);
                            }
                            else {
                                this.pageService.setPagination(res.current_page, res.last_page, this.localUrlWithParam);
                            }
                        }
                        this.serverPaginationUrl = '';
                    }
                    else {
                        this.SOURCE = [];
                    }
                }
            )
        }
        this.baseService.emitData();
        //listen to route param changes in serverUrl
        if (this.activatedRoute) {
            this.activatedRoute.paramMap.subscribe(
                (param) => {

                    // filter formation by domaine
                    if (param.has('domaine_slug')) {
                        this.serverUrlWithParam = this.serverUrl + '/par-domaine/' + param.get('domaine_slug');
                        this.params.domaine_slug = param.get('domaine_slug');
                        // add stock param to local url navigation
                        this.localUrlWithParam = this.localUrl + '/par-domaine/' + param.get('domaine_slug');
                        this.baseService.setPageTitle(this.params.domaine_slug +' - Growth Continue');
                    }

                    // filter formation by certification
                    if (param.has('certification_slug')) {
                        this.serverUrlWithParam = this.serverUrl + '/par-certification/' + param.get('certification_slug');
                        this.params.certification_slug = param.get('certification_slug');
                        // add stock param to local url navigation
                        this.localUrlWithParam = this.localUrl + '/par-certification/' + param.get('certification_slug');
                        this.baseService.setPageTitle(this.params.certification_slug +' - Growth Continue');
                    }

                       // filter formation from search keywords
                       if (param.has('keyword')) {
                        this.serverUrlWithParam = this.serverUrl + '/search/' + param.get('keyword');
                        this.params.keyword = param.get('keyword');
                        // add stock param to local url navigation
                        this.localUrlWithParam = this.localUrl + '/search/' + param.get('keyword');
                        this.baseService.setPageTitle(this.params.keyword +' - Growth Continue');
                    }

                    //generate good link for pagination if exist
                    if (param.has('page')) {
                        this.serverPaginationUrl = '?page=' + param.get('page');
                    }

                    // check if data have been got from server were component is init
                    if (this.canInitData) {
                        this.init();
                    }

                }
            )
        }

    }


    initDelete(id, index) {
        this.modalTitle = 'DELETING';
        this.currentItem = id;
        this.currentIndex = index;
    }

    delete(modalToClose = '#confirmModal', initData?: boolean, callback?: string, customUrl?: string) {
        this.requesting = true;
        let url = customUrl || this.serverUrl;
        const id = this.currentItem.id || this.currentItem;
        this.baseService.delete(url + '/' + id).subscribe(
            (res: any) => {
                if (res.success) {

                    if (!initData) {
                        this.baseService.del(this.currentIndex);
                    }
                    this.baseService.notify("success", 'SUCCESS');
                    this.baseService.closeModal(modalToClose);
                    this.currentItem = null;
                    this.requesting = false;
                    if (initData) {
                        this.init();
                    }
                }
                else {
                    this.baseService.notify('default', 'ERROR');
                    this.requesting = false;
                }
            },
            (error) => {
                this.baseService.closeModal(modalToClose);
                this.baseService.notify('default', "serverError");
                this.baseService.notify('default', 'elementToDeleteNotFound');
                this.requesting = false;
            },
            () => {
                if (callback) {
                    this[callback]();
                }
            }
        );
    }

    init(optionnalParam = '') {
        this.baseService.init(this.serverUrlWithParam + optionnalParam+ this.serverPaginationUrl, this.getDataAnyway);
    }

    setPropertieValue(properties: string, value: any) {
        if (this[properties] == value) {
            this[properties] = value;
        }
    }

    changeStep(properties: string, value: any) {
        this.steps[properties] = value;
    }

    invokeFunction(myFunction: string, value = null) {
        if (value) {
            this[myFunction](value);
        }
        else {
            this[myFunction]();
        }
    }

    toggleView(view: string) {
        this.currentView = view;
    }

    setFormControlValue(form: string, control: string, value: any) {
        this.forms[form].patchValue({ [control]: value });
    }

    // patch form controls value
    // shema [{control: '', value: ''}] for typical value
    // shema ['name'] form global value
    setArrayFormControlsValue(form: string, shema: any[], globalValue?: any) {
        if (globalValue) {
            shema.forEach((element: any) => {
                this.forms[form].patchValue({ [element]: globalValue });
            });
        }
        else {
            shema.forEach((element: any) => {
                this.forms[form].patchValue({ [element.control]: element.value });
            });
        }
    }

    getFormControlValue(form: string, control: string): string {
        return this.forms[form].controls[control].value;
    }

    setCurrentItem(item: Object) {
        this.currentItem = item;
    }

    printNow(block: string) {
        const printContent = document.getElementById(block);
        const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
        WindowPrt.document.write('<link rel="stylesheet" type="text/css" href="../../../assets/css/app.css">');
        WindowPrt.document.write('<link rel="stylesheet" type="text/css" href="../../../assets/css/custom.css">');
        WindowPrt.document.write(printContent.innerHTML);
        WindowPrt.document.close();
        WindowPrt.focus();
        WindowPrt.print();
        WindowPrt.close();
    }


}
