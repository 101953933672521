import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";

declare var $: any;
declare var Swiper: any;
declare var jQuery: any;


@Injectable({
  providedIn: 'root'
})

export class AppService extends BaseService {
  constructor() {
    super('');
  }


  lauchJs() {
    if ($('.owl-carousel').exists()) {

      $('.owl-carousel').each(function () {
        var $this = $(this),
          $items = ($this.data('items')) ? $this.data('items') : 3,
          $items_tablet = ($this.data('items-tablet')) ? $this.data('items-tablet') : 2,
          $items_mobile = ($this.data('items-mobile')) ? $this.data('items-mobile') : 1,
          $items_mobile_wide = ($this.data('items-mobile-wide')) ? $this.data('items-mobile-wide') : 2,
          $loop = ($this.data('loop')) ? $this.data('loop') : true,
          $autoplay = ($this.attr('data-autoplay')) ? $this.data('autoplay') : true,
          $autospeed = ($this.data('autospeed')) ? $this.data('autospeed') : 3000,
          $smartspeed = ($this.data('smartspeed')) ? $this.data('smartspeed') : 800,
          $autohgt = ($this.data('autoheight')) ? $this.data('autoheight') : false,
          $space = ($this.data('space')) ? $this.data('space') : 0,
          $dots = ($this.data('dots')) ? $this.data('dots') : false,
          $stageOuterClass = ($this.data('state-outer-class')) ? $this.data('state-outer-class') : null,
          $dotsclass = ($this.data('dots-class')) ? $this.data('dots-class') : false,
          $arrow = ($this.data('arrow')) ? $this.data('arrow') : false;
        $(this).owlCarousel({
          loop: $loop,
          items: $items,
          autoplay: $autoplay,
          autoplayTimeout: $autospeed,
          smartSpeed: $smartspeed,
          margin: $space,
          dots: $dots,
          nav: $arrow,
          dotsClass: 'owl-dots' + ' ' + $dotsclass,
          stageOuterClass: 'owl-stage-outer' + ' ' + $stageOuterClass,
          navText: ["<i class='ti-angle-left'></i>", "<i class='ti-angle-right'></i>"],
          autoHeight: $autohgt,
          responsive: {
            0: {
              items: $items_mobile,
            },
            576: {
              items: $items_mobile_wide,
            },
            750: {
              items: $items_tablet,
            },
            1170: {
              items: $items,
            }
          }

        });
      });
    }
  }



}